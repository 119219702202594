<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20">
          <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate">新增账号</el-button>
        </div>
      </template>

      <template slot="menu" slot-scope="{row}">
        <el-button size="small" type="text" icon="el-icon-edit" @click="handleEdit(row)">修改</el-button>
        <el-button size="small" type="text" icon="el-icon-refresh" @click="handleRefresh(row)">密码重置</el-button>
        <el-button type="text" size="small" icon="el-icon-connection" @click="handleAuthorize(row)">授权</el-button>
        <el-button type="text" size="small" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
      </template>

    </avue-crud>

    <el-dialog
        :title="form.id ? '编辑' : '新增'"
        v-if="show"
        :visible.sync="show"
        width="60%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 :upload-error="uploadError"
                 :upload-delete="uploadDelete"
                 :upload-before="uploadBefore"
                 :upload-after="uploadAfter"
                 @submit="handleSubmit">

        <template slot="phone">
          <el-input v-model="form.phone" type="text" maxlength="11"
                    placeholder="请输入 联系电话" clearable show-word-limit
                    onkeyup="this.value=this.value.replace(/^(0+)|\D+/g,'')"
                    @blur="changeBlur($event,'phone')">
          </el-input>
        </template>

        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>


    <el-dialog
        title="用户角色配置"
        v-if="showRole"
        :visible.sync="showRole"
        width="30%"
        :before-close="handleCloseRole">
      <avue-form ref="form"
                 v-model="formRole"
                 :option="formOptionRole"
                 @submit="handleSubmitRole">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleCloseRole">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {userlist, userDelete, passwordReset, userAdd, userUpdate, regionList, userRole, roleView, roleUpdate, userView, cityList} from "@/api/system";
export default {
  name: "index",
  data(){
    return{
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '账号管理',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label:'用户名',
            prop:'username',
          },
          {
            label:'昵称',
            prop:'fullName'
          },
          {
            label:'联系电话',
            prop:'phone'
          },
          {
            label:'启用状态',
            prop:'state',
            type: 'radio',
            dicData: [{label: '启用',value: 1},{label: '禁用',value: 2}]
          },
          {
            label:'创建用户',
            prop:'createBy',
          },
          {
            label:'创建时间',
            prop:'createTime'
          },
          {
            label:'修改时间',
            prop:'updateTime'
          },
          {
            label:'最后登录IP',
            prop:'loginIp'
          },
          {
            label:'最后登录时间',
            prop:'loginTime'
          }
        ]
      },

      show: false,
      form: {},
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: '头像',
            prop: 'headPortrait',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '最多上传1张，限制：20M',
            fileSize: 20 * 1024,
            span: 24,
            rules: [{
              required: true,
              message: "请上传头像",
              trigger: "blur"
            }]
          },
          {
            label: "用户名",
            prop: "username",
            maxlength: 20,
            showWordLimit:true,
            span: 12,
            rules: [
              {
                required: true,
                message: "请输入用户名",
                trigger: "blur"
              }
            ],
            display: true
          },
          {
            label: "密码",
            prop: "password",
            maxlength: 30,
            showWordLimit:true,
            span: 12,
            rules: [
              {
                required: true,
                message: "请输入密码",
                trigger: "blur"
              }
            ],
            display: true
          },
          {
            label: "昵称",
            prop: "fullName",
            maxlength: 20,
            showWordLimit:true,
            span: 12,
            rules: [
              {
                required: true,
                message: "请输入昵称",
                trigger: "blur"
              }
            ]
          },
          {
            label: "联系电话",
            prop: "phone",
            rules: [
              {
                required: true,
                message: "请输入联系电话",
                trigger: "blur"
              },{
                validator: (rule, value, callback) => {
                  if(!/^1[3456789]\d{9}$/.test(value)){
                    callback(new Error('请输入正确的联系电话'));
                  }else{
                    callback();
                  }
                },
                trigger: 'blur'
              }
            ]
          },
          {
            label: "所属角色",
            prop: "role",
            type: "select",
            dicData: [],
            span: 12,
            multiple: true,
            props: {
              label: "name",
              value: "id"
            },
            rules: [
              {
                required: true,
                message: "请选择所属角色",
                trigger: "blur"
              }
            ]
          },
          {
            label: "状态",
            prop: "state",
            type: "radio",
            value: 1,
            dicData: [
              {label: "启用", value: 1},
              {label: "禁用", value: 2}
            ],
            rules: [
              {
                required: true,
                message: "请选择状态",
                trigger: "blur"
              }
            ]
          },
          {
            label: '经纪人管理区域',
            prop: 'province',
            type: 'cascader',
            dicData: [],
            props: {
              label: 'name',
              value: 'id'
            },
            checkStrictly: true,
            change: (e)=>{
              const val = e.value;
              if(val.length === 1){
                this.form.provinceId = val[0];
                this.form.cityId = '';
              }else if(val.length === 2){
                this.form.provinceId = val[0];
                this.form.cityId = val[1];
              }else{
                this.form.provinceId = '';
                this.form.cityId = '';
              }
              // console.log(this.form)
            },
            rules: [
              {
                required: true,
                message: "请选择区域",
                trigger: "blur"
              }
            ]
          },
          {
            label: '房源区域',
            prop: 'build',
            type: 'cascader',
            dicData: [],
            props: {
              label: 'name',
              value: 'id'
            },
            checkStrictly: true,
            change: (e)=>{
              const val = e.value;
              if(val.length === 1){
                this.form.buildCityId = val[0];
                this.form.buildCountyId = '';
              }else if(val.length === 2){
                this.form.buildCityId = val[0];
                this.form.buildCountyId = val[1];
              }else{
                this.form.buildCityId = '';
                this.form.buildCountyId = '';
              }
              // console.log(this.form)
            },
            rules: [
              {
                required: true,
                message: "请选择区域",
                trigger: "blur"
              }
            ]
          }
        ]
      },

      showRole: false,
      formRole: {},
      formOptionRole: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 100,
        column: [
          {
            label: '角色配置',
            prop: 'role',
            type: "cascader",
            span: 24,
            dicData: [],
            props: {
              label: 'name',
              value: 'id'
            },
            multiple: true
          }
        ]
      }
    }
  },
  async mounted() {
    await this.getRegionList();
    await this.getCityList();
    await this.getUserRole();
  },
  methods: {
    // 全国
    async getRegionList(){
      const { data } = await regionList()
      // console.log(data)
      const one = [{
        code: 100000,
        id: "100000",
        name: "全国",
        parentId: "0"
      }]
      const province = this.findObject(this.formOption.column, 'province');
      province.dicData = one.concat(data);
    },
    // 后台配置城市
    async getCityList(){
      const { data } = await cityList();
      // console.log(data)
      const one = [{
        code: 100000,
        id: "100000",
        name: "全国",
        parentId: "0"
      }]
      const build = this.findObject(this.formOption.column, 'build');
      build.dicData = one.concat(data);
    },
    // 角色
    async getUserRole(){
      const { data } = await userRole();
      // console.log(data)
      const formOption = this.findObject(this.formOption.column, 'role');
      const formOptionRole = this.findObject(this.formOptionRole.column, 'role');
      formOption.dicData = data || [];
      formOptionRole.dicData = data || [];
    },
    onLoad(){
      this.showLoading = true;
      userlist({
        page: this.page.currentPage,
        size: this.page.pageSize
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 新增账号
    handleCreate(){
      // console.log('新增账号')
      const username = this.findObject(this.formOption.column, 'username');
      const password = this.findObject(this.formOption.column, 'password');
      username.display = true;
      password.display = true;
      this.show = true;
    },
    // 修改
    async handleEdit(row){
      // console.log('修改',row)
      if(await this.getUserView(row.id)){
        const username = this.findObject(this.formOption.column, 'username');
        const password = this.findObject(this.formOption.column, 'password');
        username.display = false;
        password.display = false;
        this.show = true;
      }
    },
    async getUserView(id){
      const res = await userView(id)
      // console.log(res)
      if(res.code === 200){
        this.form = {
          ...res.data,
          province: [res.data.provinceId,res.data.cityId].filter(item => item),
          build: [res.data.buildCityId,res.data.buildCountyId].filter(item => item)
        }
        return true
      }else{
        this.$message.error(res.msg)
        return false
      }
    },
    // 密码重置
    handleRefresh(row){
      // console.log('密码重置',row)
      this.$confirm('是否重置密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        passwordReset(row.id).then(res => {
          if(res.code === 200){
            this.$notify({
              title: '重置密码为123456',
              duration: 5000,
              type: 'success'
            });
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    // 授权
    async handleAuthorize(row){
      // console.log('授权',row)
      const res = await roleView(row.id);
      // console.log(res)
      if(res.code === 200){
        this.showRole = true;
        this.formRole = {
          id: row.id,
          role: res.data
        }
      }else{
        this.$message.error(res.msg)
      }
    },
    // 删除
    handleDelete(row){
      // console.log('删除',row)
      this.$confirm('是否删除此账号数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        userDelete(row.id).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    // 提交
    handleSubmit(form,done){
      // console.log(form)
      const { role,...rest } = form;
      if(form.id){
        userUpdate({
          ...rest,
          roleIds: role.toString()
        }).then(res=>{
          if(res.code === 200){
            this.$message.success('修改成功');
            this.show = false;
            this.onLoad();
            setTimeout(()=>{
              this.resetForm();
            },200)
          }else{
            this.$message.error(res.msg)
            done();
          }
        }).catch(()=> done())
      }else{
        userAdd({
          ...rest,
          roleIds: role.toString()
        }).then(res=>{
          if(res.code === 200){
            this.$message.success('添加成功');
            this.show = false;
            this.onLoad();
            setTimeout(()=>{
              this.resetForm();
            },200)
          }else{
            this.$message.error(res.msg)
            done();
          }
        }).catch(()=> done())
      }
    },
    // 关闭
    handleClose(){
      this.show = false;
      setTimeout(()=>{
        this.resetForm();
      },200)
    },
    resetForm(){
      this.form = {
        id: ''
      }
    },

    // 限制输入框
    changeBlur(e,text){
      // console.log(e.target.value,text)
      // console.log(isNaN(e.target.value))
      const value = e.target.value;
      if(value){
        if(isNaN(e.target.value)){
          e.target.value = ''
          this.form[text] = ''
        }else{
          // console.log(Number(value))
          this.form[text] = value
        }
      }else{
        e.target.value = '';
        this.form[text] = ''
      }
    },
    // 图片上传
    uploadDelete(file,column) {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
    uploadAfter(res, done,loading,column) {
      done()
      // console.log(res)
      // console.log(column)
      if(!res.path){
        this.$message.error('上传失败');
        loading()
      }else{
        column.propsHttp.name = res.path;
      }
    },

    // 用户角色配置
    // 提交
    handleSubmitRole(form,done){
      // console.log(form)
      roleUpdate({
        id: form.id,
        roleIds: form.role.toString()
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('配置成功');
          this.resetFormRole();
          this.onLoad();
          this.showRole = false;
        }else{
          this.$message.error(res.msg)
          done();
        }
      }).catch(()=> done())
    },
    // 关闭
    handleCloseRole(){
      this.showRole = false;
      this.resetFormRole();
    },
    resetFormRole(){
      this.formRole = {
        id: '',
        role: []
      }
    }
  }
}
</script>

<style scoped>

</style>